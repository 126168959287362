import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { FixedImg } from 'src/embeds/image/image-dato';

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  customData = JSON.parse(customData);
  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        {heading && <h2>{heading}</h2>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {callouts.length > 0 ? (
          <Grid
            columns={`repeat(${Math.min(callouts.length, 3)}, minmax(0, 1fr))`}
            spacious
            style={{ marginTop: 80 }}
          >
            {callouts.map(({ heading, content, contentNode, media, customData }, i) => {
              customData = JSON.parse(customData);
              return (
                <Column key={i} {...customData?.flags?.column}>
                  {media && (
                    <FixedImg
                      {...customData?.flags?.image}
                      css={`
                        margin-bottom: 20px;
                      `}
                      data={media}
                      width={media.width}
                      role="presentation"
                    />
                  )}
                  <h3>{heading}</h3>
                  {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
                </Column>
              );
            })}
          </Grid>
        ) : null}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}
